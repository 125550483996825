import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from './../App'
import { useLocation } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import SideNavigation from '@cloudscape-design/components-themed/side-navigation'

// https://cloudscape.design/components/side-navigation
export function RadSideNavigation ({ setNavigationOpen }) {
  const { setError, navigationOpen } = useContext(AppContext)
  const location = useLocation()
  const isMobile = useIsMobile()
  const rootHref = () => {
    let path = location.pathname
    let last = path.split('/').pop()
    while (!isNaN(parseInt(last, 10)) || last === 'edit') {
      path = path.replace(`/${last}`, '')
      last = path.split('/').pop()
    }
    return path
  }
  const [activeHref, setActiveHref] = useState(rootHref)

  const handleClickOutsideSideNav = (event) => {
    // early return if side nav is already closed.
    if (!navigationOpen) return

    // an array of elements that the event traveled through, including any Shadow DOM elements
    const nodeArr = event.composedPath()

    // check user click location
    const userClick = nodeArr.reduce((acc, curVal, idx) => {
      const isSideNav = curVal.classList?.value.includes('awsui_navigation_')
      const isSideNavMenuIcon = curVal.classList?.value.includes('awsui_show-navigation_')
      if (isSideNav) acc = { ...acc, isSideNav: true }
      if (isSideNavMenuIcon) acc = { ...acc, isSideNavMenuIcon: true }
      return acc
    }, { isSideNav: false, isSideNavMenuIcon: false })

    // close side nav if user click is not in side nav
    if (!userClick.isSideNav && !userClick.isSideNavMenuIcon) {
      setNavigationOpen(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleClickOutsideSideNav)

    return () => {
      document.body.removeEventListener('click', handleClickOutsideSideNav)
    }
  }, [navigationOpen])

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: '/', text: 'Metadata Tracker' }}
      onFollow={(event) => {
        if (isMobile) setNavigationOpen(false)
        setError(null) // Chipi - I might not need this
        setActiveHref(event.detail.href)
        if (event.detail.type === 'link') setNavigationOpen(false)
      }}
      items={[
        { type: 'link', text: 'Home', href: '/' },
        { type: 'link', text: 'Organizations', href: '/organization' },
        { type: 'link', text: 'Projects', href: '/project' },
        { type: 'link', text: 'People', href: '/person' },
        { type: 'divider' },
        {
          type: 'section',
          text: 'Site Administration',
          items: [
            { type: 'link', text: 'Users', href: '/user' },
            { type: 'link', text: 'Roles', href: '/role' },
            { type: 'link', text: 'Options', href: '/option' }
          ]
        }
      ]}
    />
  )
}
