
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_fvjdu_nfftc_145",
  "button": "awsui_button_fvjdu_nfftc_185",
  "dots": "awsui_dots_fvjdu_nfftc_186",
  "button-disabled": "awsui_button-disabled_fvjdu_nfftc_214",
  "arrow": "awsui_arrow_fvjdu_nfftc_219",
  "page-number": "awsui_page-number_fvjdu_nfftc_229",
  "button-current": "awsui_button-current_fvjdu_nfftc_235",
  "page-item": "awsui_page-item_fvjdu_nfftc_252",
  "root-disabled": "awsui_root-disabled_fvjdu_nfftc_270"
};
  