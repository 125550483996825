import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadTextContent } from '../common/RadTextContent'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadGrid } from '../common/RadGrid'
import { RadCards } from '../common/RadCards'
import { RadBadge } from '../common/RadBadge'
import { RadButton } from '../common/RadButton'
import { RadLink } from '../common/RadLink'
import { RadTabs } from '../common/RadTabs'
import { RadStatusIndicator } from '../common/RadStatusIndicator'
import { formatDate } from '../common/utilities'

export function OrganizationDetail () {
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const { data: organization } = useGet(organizationId != null ? `/api/organization/${organizationId}` : '')
  const remove = useDelete(`/api/organization/${organizationId}`, () => { navigate('/organization') })
  const confirmDelete = useConfirm('Delete organization?', 'Delete organization permanently? This action cannot be undone.', () => { remove() })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (organization != null) {
    const focusAreas = organization.focusAreas.map(x => x.name)
    const people = organization.people.map(x => ({ name: x.name, email: x.email, id: x.id }))
    const projects = organization.projects.map(x => ({
      name: x.name,
      description: x.description,
      statusName: x.statusType.name,
      id: x.id,
      startDate: x.start_date,
      endDate: x.end_date
    }))

    return (
      <RadAppLayout
        name={organization.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={false}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {organization.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer header={<RadHeader variant='h2'>Details</RadHeader>}>
              <RadSpaceBetween size='s'>
                <RadColumnLayout columns={3} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>Type</RadBox>
                    <div>{organization.organizationType?.name ?? '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>City</RadBox>
                    <div>{organization.city ?? '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>State</RadBox>
                    <div>{organization.state ?? '-'}</div>
                  </div>
                </RadColumnLayout>
                <div>
                  <RadBox variant='awsui-key-label'>Focus areas</RadBox>
                  <div>
                    {
                      focusAreas.length > 0
                        ? (
                          <RadSpaceBetween direction='horizontal' size='xs'>
                            {focusAreas.sort().map((x, idx) => <RadBadge key={idx}>{x}</RadBadge>)}
                          </RadSpaceBetween>
                        )
                        : '-'
                    }
                  </div>
                </div>
              </RadSpaceBetween>
            </RadContainer>

            <RadTabs
              tabs={[
                {
                  label: 'People',
                  id: 'people',
                  content: <People {...{ people }} />
                },
                {
                  label: 'Projects',
                  id: 'projects',
                  content: <Projects {...{ projects }} />
                }
              ]}
            />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function People ({ people }) {
  return (
    <RadContainer header={<RadHeader variant='h2'>People</RadHeader>}>
      <RadTextContent>
        {
          people.length > 0
            ? (
              <ul>
                <RadSpaceBetween size='m'>
                  {
                    people.sort((a, b) => { return a.lastName - b.lastName })
                      .map((person, idx) => (
                        <li key={idx}>
                          <RadLink href={`/person/${person.id}`}>{person.name} ({person.email})</RadLink>
                        </li>)
                      )
                  }
                </RadSpaceBetween>
              </ul>
            )
            : '-'
        }
      </RadTextContent>
    </RadContainer>
  )
}

function Projects ({ projects }) {
  const getStatusType = (statusName) => {
    let statusBadge = {}
    switch (statusName) {
      case undefined:
        statusBadge = { status: 'Inactive', type: 'in-progress' }
        break
      case 'Active':
        statusBadge = { status: 'Active', type: 'pending' }
        break
      case 'Paused':
        statusBadge = { status: 'Paused', type: 'warning' }
        break
      case 'Completed':
        statusBadge = { status: 'Completed', type: 'success' }
        break
      case 'Cancelled':
        statusBadge = { status: 'Cancelled', type: 'error' }
        break
      default:
        statusBadge = { status: '', type: 'in-progress' }
    }
    return statusBadge
  }

  return (
    <RadSpaceBetween size='m'>
      <RadCards
        ariaLabels={{
          itemSelectionLabel: (e, t) => `select ${t.name}`,
          selectionGroupLabel: 'Item selection'
        }}
        cardDefinition={{
          header: item => (
            <RadGrid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
              <RadHeader>
                <RadLink fontSize='heading-xl' href={`/project/${item.id}`}>
                  {item.name}
                </RadLink>
              </RadHeader>
              <RadBox textAlign='right'>
                <RadStatusIndicator type={getStatusType(item.statusName).type}>{getStatusType(item.statusName).status}</RadStatusIndicator>
              </RadBox>
            </RadGrid>
          ),
          sections: [
            {
              id: item => item.name + item.id,
              content: item => {
                return (
                  <RadSpaceBetween size='s'>
                    <RadBox>
                      <RadSpaceBetween direction='horizontal' size='m'>
                        <div>
                          <RadBox variant='awsui-key-label'>Start date</RadBox>
                          <div>{item.startDate != null ? formatDate(item.startDate) : '-'}</div>
                        </div>
                        <div>
                          <RadBox variant='awsui-key-label'>End date</RadBox>
                          <div>{item.endDate != null ? formatDate(item.endDate) : '-'}</div>
                        </div>
                      </RadSpaceBetween>
                    </RadBox>
                    <RadBox>
                      <div>
                        <RadBox variant='awsui-key-label'>Description</RadBox>
                        <div>{item.description != null ? item.description : '-'}</div>
                      </div>
                    </RadBox>
                  </RadSpaceBetween>
                )
              }
            }
          ]
        }}
        cardsPerRow={[
          { cards: 1 }
        ]}
        items={projects ?? []}
        variant='full-page'
      />
    </RadSpaceBetween>
  )
}
