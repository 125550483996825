
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_145pa_1qkki_145",
  "focus-lock": "awsui_focus-lock_145pa_1qkki_177",
  "calendar": "awsui_calendar_145pa_1qkki_181",
  "date-picker-container": "awsui_date-picker-container_145pa_1qkki_197",
  "date-picker-trigger": "awsui_date-picker-trigger_145pa_1qkki_202",
  "date-picker-input": "awsui_date-picker-input_145pa_1qkki_206",
  "open-calendar-button": "awsui_open-calendar-button_145pa_1qkki_211"
};
  