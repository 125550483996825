
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_2qdw9_1rn64_181",
  "refresh": "awsui_refresh_2qdw9_1rn64_217",
  "root-no-actions": "awsui_root-no-actions_2qdw9_1rn64_217",
  "root-variant-h2": "awsui_root-variant-h2_2qdw9_1rn64_220",
  "root-variant-h3": "awsui_root-variant-h3_2qdw9_1rn64_220",
  "root-has-description": "awsui_root-has-description_2qdw9_1rn64_220",
  "root-variant-h1": "awsui_root-variant-h1_2qdw9_1rn64_229",
  "main": "awsui_main_2qdw9_1rn64_239",
  "no-wrap": "awsui_no-wrap_2qdw9_1rn64_249",
  "main-variant-h1": "awsui_main-variant-h1_2qdw9_1rn64_255",
  "actions": "awsui_actions_2qdw9_1rn64_263",
  "actions-centered": "awsui_actions-centered_2qdw9_1rn64_268",
  "actions-variant-h1": "awsui_actions-variant-h1_2qdw9_1rn64_271",
  "actions-variant-h2": "awsui_actions-variant-h2_2qdw9_1rn64_275",
  "actions-variant-h3": "awsui_actions-variant-h3_2qdw9_1rn64_279",
  "title": "awsui_title_2qdw9_1rn64_295",
  "title-variant-h1": "awsui_title-variant-h1_2qdw9_1rn64_300",
  "title-variant-h2": "awsui_title-variant-h2_2qdw9_1rn64_307",
  "title-variant-h3": "awsui_title-variant-h3_2qdw9_1rn64_313",
  "virtual-space": "awsui_virtual-space_2qdw9_1rn64_334",
  "info": "awsui_info_2qdw9_1rn64_340",
  "description": "awsui_description_2qdw9_1rn64_344",
  "description-variant-h1": "awsui_description-variant-h1_2qdw9_1rn64_351",
  "description-variant-h2": "awsui_description-variant-h2_2qdw9_1rn64_355",
  "description-variant-h3": "awsui_description-variant-h3_2qdw9_1rn64_362",
  "heading": "awsui_heading_2qdw9_1rn64_371",
  "heading-variant-h1": "awsui_heading-variant-h1_2qdw9_1rn64_382",
  "heading-variant-h2": "awsui_heading-variant-h2_2qdw9_1rn64_387",
  "heading-variant-h3": "awsui_heading-variant-h3_2qdw9_1rn64_392",
  "heading-text": "awsui_heading-text_2qdw9_1rn64_398",
  "heading-text-variant-h1": "awsui_heading-text-variant-h1_2qdw9_1rn64_401",
  "heading-text-variant-h2": "awsui_heading-text-variant-h2_2qdw9_1rn64_409",
  "heading-text-variant-h3": "awsui_heading-text-variant-h3_2qdw9_1rn64_417",
  "counter": "awsui_counter_2qdw9_1rn64_426"
};
  