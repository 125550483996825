
    import './styles.scoped.css';
    export default {
  "layout": "awsui_layout_5gtk3_1kjsb_145",
  "background": "awsui_background_5gtk3_1kjsb_159",
  "is-overlap-disabled": "awsui_is-overlap-disabled_5gtk3_1kjsb_164",
  "header-background": "awsui_header-background_5gtk3_1kjsb_167",
  "notifications": "awsui_notifications_5gtk3_1kjsb_171",
  "breadcrumbs": "awsui_breadcrumbs_5gtk3_1kjsb_176",
  "default-padding": "awsui_default-padding_5gtk3_1kjsb_181",
  "header-wrapper": "awsui_header-wrapper_5gtk3_1kjsb_185",
  "with-divider": "awsui_with-divider_5gtk3_1kjsb_190",
  "content": "awsui_content_5gtk3_1kjsb_193",
  "has-header": "awsui_has-header_5gtk3_1kjsb_197",
  "is-visual-refresh": "awsui_is-visual-refresh_5gtk3_1kjsb_204",
  "has-default-background": "awsui_has-default-background_5gtk3_1kjsb_204",
  "has-notifications": "awsui_has-notifications_5gtk3_1kjsb_208"
};
  