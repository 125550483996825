
    import './styles.scoped.css';
    export default {
  "dialog": "awsui_dialog_1d2i7_miaej_169",
  "modal-slide-up": "awsui_modal-slide-up_1d2i7_miaej_1",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1d2i7_miaej_1",
  "refresh": "awsui_refresh_1d2i7_miaej_192",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_1d2i7_miaej_1",
  "root": "awsui_root_1d2i7_miaej_225",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_1d2i7_miaej_1",
  "hidden": "awsui_hidden_1d2i7_miaej_302",
  "focus-lock": "awsui_focus-lock_1d2i7_miaej_306",
  "small": "awsui_small_1d2i7_miaej_326",
  "medium": "awsui_medium_1d2i7_miaej_329",
  "large": "awsui_large_1d2i7_miaej_332",
  "max": "awsui_max_1d2i7_miaej_335",
  "breakpoint-xs": "awsui_breakpoint-xs_1d2i7_miaej_335",
  "container": "awsui_container_1d2i7_miaej_341",
  "content": "awsui_content_1d2i7_miaej_382",
  "no-paddings": "awsui_no-paddings_1d2i7_miaej_387",
  "header": "awsui_header_1d2i7_miaej_392",
  "header--text": "awsui_header--text_1d2i7_miaej_404",
  "footer": "awsui_footer_1d2i7_miaej_408",
  "footer--stuck": "awsui_footer--stuck_1d2i7_miaej_417",
  "dismiss-control": "awsui_dismiss-control_1d2i7_miaej_429",
  "modal-open": "awsui_modal-open_1d2i7_miaej_433"
};
  