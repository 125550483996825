
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_l0dv0_1qapx_145",
  "header": "awsui_header_l0dv0_1qapx_179",
  "header-link": "awsui_header-link_l0dv0_1qapx_192",
  "header-link--has-logo": "awsui_header-link--has-logo_l0dv0_1qapx_202",
  "header-link-text": "awsui_header-link-text_l0dv0_1qapx_202",
  "header-logo": "awsui_header-logo_l0dv0_1qapx_207",
  "header-logo--stretched": "awsui_header-logo--stretched_l0dv0_1qapx_213",
  "items-control": "awsui_items-control_l0dv0_1qapx_218",
  "list-container": "awsui_list-container_l0dv0_1qapx_222",
  "with-toolbar": "awsui_with-toolbar_l0dv0_1qapx_230",
  "divider-header": "awsui_divider-header_l0dv0_1qapx_230",
  "list": "awsui_list_l0dv0_1qapx_222",
  "list-variant-root": "awsui_list-variant-root_l0dv0_1qapx_243",
  "list-variant-root--first": "awsui_list-variant-root--first_l0dv0_1qapx_250",
  "list-variant-expandable-link-group": "awsui_list-variant-expandable-link-group_l0dv0_1qapx_254",
  "list-item": "awsui_list-item_l0dv0_1qapx_258",
  "section": "awsui_section_l0dv0_1qapx_269",
  "expandable-link-group": "awsui_expandable-link-group_l0dv0_1qapx_270",
  "section--no-ident": "awsui_section--no-ident_l0dv0_1qapx_273",
  "expandable-link-group--no-ident": "awsui_expandable-link-group--no-ident_l0dv0_1qapx_274",
  "refresh": "awsui_refresh_l0dv0_1qapx_282",
  "list-variant-section-group": "awsui_list-variant-section-group_l0dv0_1qapx_293",
  "section-group": "awsui_section-group_l0dv0_1qapx_300",
  "section-group-title": "awsui_section-group-title_l0dv0_1qapx_311",
  "link": "awsui_link_l0dv0_1qapx_315",
  "link-active": "awsui_link-active_l0dv0_1qapx_324",
  "info": "awsui_info_l0dv0_1qapx_361",
  "external-icon": "awsui_external-icon_l0dv0_1qapx_365",
  "divider": "awsui_divider_l0dv0_1qapx_230",
  "divider-default": "awsui_divider-default_l0dv0_1qapx_374"
};
  