
    import './styles.scoped.css';
    export default {
  "link": "awsui_link_4c84z_1q0zg_145",
  "variant-secondary": "awsui_variant-secondary_4c84z_1q0zg_211",
  "variant-primary": "awsui_variant-primary_4c84z_1q0zg_246",
  "variant-info": "awsui_variant-info_4c84z_1q0zg_279",
  "variant-value-large": "awsui_variant-value-large_4c84z_1q0zg_314",
  "variant-top-navigation": "awsui_variant-top-navigation_4c84z_1q0zg_346",
  "variant-recovery": "awsui_variant-recovery_4c84z_1q0zg_379",
  "button": "awsui_button_4c84z_1q0zg_412",
  "color-inverted": "awsui_color-inverted_4c84z_1q0zg_447",
  "font-size-body-s": "awsui_font-size-body-s_4c84z_1q0zg_468",
  "font-size-body-m": "awsui_font-size-body-m_4c84z_1q0zg_475",
  "font-size-heading-xs": "awsui_font-size-heading-xs_4c84z_1q0zg_481",
  "font-size-heading-s": "awsui_font-size-heading-s_4c84z_1q0zg_487",
  "font-size-heading-m": "awsui_font-size-heading-m_4c84z_1q0zg_494",
  "font-size-heading-l": "awsui_font-size-heading-l_4c84z_1q0zg_501",
  "font-size-heading-xl": "awsui_font-size-heading-xl_4c84z_1q0zg_508",
  "font-size-display-l": "awsui_font-size-display-l_4c84z_1q0zg_515",
  "font-size-inherit": "awsui_font-size-inherit_4c84z_1q0zg_522",
  "icon-wrapper": "awsui_icon-wrapper_4c84z_1q0zg_530",
  "icon": "awsui_icon_4c84z_1q0zg_530"
};
  