
    import './styles.scoped.css';
    export default {
  "header-cell": "awsui_header-cell_1spae_15zfd_149",
  "header-cell-fake-focus": "awsui_header-cell-fake-focus_1spae_15zfd_183",
  "header-cell-sticky": "awsui_header-cell-sticky_1spae_15zfd_204",
  "header-cell-stuck": "awsui_header-cell-stuck_1spae_15zfd_207",
  "header-cell-variant-full-page": "awsui_header-cell-variant-full-page_1spae_15zfd_207",
  "header-cell-hidden": "awsui_header-cell-hidden_1spae_15zfd_213",
  "header-cell-sortable": "awsui_header-cell-sortable_1spae_15zfd_216",
  "sticky-cell": "awsui_sticky-cell_1spae_15zfd_219",
  "sticky-cell-pad-left": "awsui_sticky-cell-pad-left_1spae_15zfd_237",
  "has-selection": "awsui_has-selection_1spae_15zfd_237",
  "sticky-cell-last-inline-start": "awsui_sticky-cell-last-inline-start_1spae_15zfd_240",
  "resize-divider": "awsui_resize-divider_1spae_15zfd_245",
  "sticky-cell-last-inline-end": "awsui_sticky-cell-last-inline-end_1spae_15zfd_252",
  "sorting-icon": "awsui_sorting-icon_1spae_15zfd_262",
  "edit-icon": "awsui_edit-icon_1spae_15zfd_270",
  "header-cell-content": "awsui_header-cell-content_1spae_15zfd_276",
  "header-cell-content-expandable": "awsui_header-cell-content-expandable_1spae_15zfd_282",
  "header-cell-disabled": "awsui_header-cell-disabled_1spae_15zfd_334",
  "header-cell-sorted": "awsui_header-cell-sorted_1spae_15zfd_334",
  "header-cell-text": "awsui_header-cell-text_1spae_15zfd_348",
  "header-cell-text-wrap": "awsui_header-cell-text-wrap_1spae_15zfd_352",
  "header-cell-ascending": "awsui_header-cell-ascending_1spae_15zfd_358",
  "header-cell-descending": "awsui_header-cell-descending_1spae_15zfd_359",
  "is-visual-refresh": "awsui_is-visual-refresh_1spae_15zfd_369",
  "has-striped-rows": "awsui_has-striped-rows_1spae_15zfd_473",
  "sticky-cell-pad-inline-start": "awsui_sticky-cell-pad-inline-start_1spae_15zfd_473"
};
  