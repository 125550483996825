import React from 'react'
import { useGet } from '../hooks/useGet'
import { RadAdaptiveList } from '../common/RadAdaptiveList'

export function ProjectList () {
  const { data: focusAreaOptions } = useGet('/api/option/projectFocusArea')
  const { data: statusOptions } = useGet('/api/option/projectStatusType?required=true')
  const { data: funderOptions } = useGet('/api/option/organization')
  const doneLoading = focusAreaOptions != null && statusOptions != null && funderOptions != null

  if (doneLoading) {
    return (
      <RadAdaptiveList
        entity='project'
        hasSearchByOrg
        create={true}
        fields={[
          { header: 'Name', name: 'name', link: true },
          { header: 'Status', name: 'statusType.name' },
          { header: 'Start Date', name: 'startDateFormat' },
          { header: 'End Date', name: 'endDateFormat' }
        ]}
        filters={[{
          type: 'MultiSelect',
          name: 'focusArea',
          label: 'Project focus area',
          options: focusAreaOptions,
          placeholder: 'Select project focus area',
          expandToViewport: true
        }, {
          type: 'MultiSelect',
          name: 'status',
          label: 'Project status',
          options: statusOptions,
          placeholder: 'Select project status',
          expandToViewport: true
        }, {
          type: 'MultiSelect',
          name: 'funder',
          label: 'Project funder',
          options: funderOptions,
          placeholder: 'Select project funder',
          expandToViewport: true
        }, {
          type: 'DateRange',
          name: 'dates',
          labels: ['Projects from', 'Projects to']
        }]}
      />
    )
  }
}
