
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_14iqq_irvl0_189",
  "fit-height": "awsui_fit-height_14iqq_irvl0_222",
  "with-side-media": "awsui_with-side-media_14iqq_irvl0_227",
  "variant-default": "awsui_variant-default_14iqq_irvl0_230",
  "variant-stacked": "awsui_variant-stacked_14iqq_irvl0_230",
  "refresh": "awsui_refresh_14iqq_irvl0_238",
  "sticky-enabled": "awsui_sticky-enabled_14iqq_irvl0_288",
  "with-top-media": "awsui_with-top-media_14iqq_irvl0_300",
  "content-wrapper": "awsui_content-wrapper_14iqq_irvl0_305",
  "content-wrapper-fit-height": "awsui_content-wrapper-fit-height_14iqq_irvl0_310",
  "media": "awsui_media_14iqq_irvl0_317",
  "media-top": "awsui_media-top_14iqq_irvl0_335",
  "media-side": "awsui_media-side_14iqq_irvl0_340",
  "header": "awsui_header_14iqq_irvl0_346",
  "header-full-page": "awsui_header-full-page_14iqq_irvl0_351",
  "header-with-media": "awsui_header-with-media_14iqq_irvl0_354",
  "header-sticky-disabled": "awsui_header-sticky-disabled_14iqq_irvl0_360",
  "header-sticky-enabled": "awsui_header-sticky-enabled_14iqq_irvl0_364",
  "header-stuck": "awsui_header-stuck_14iqq_irvl0_370",
  "header-variant-cards": "awsui_header-variant-cards_14iqq_irvl0_380",
  "header-dynamic-height": "awsui_header-dynamic-height_14iqq_irvl0_383",
  "with-paddings": "awsui_with-paddings_14iqq_irvl0_389",
  "with-hidden-content": "awsui_with-hidden-content_14iqq_irvl0_398",
  "header-variant-full-page": "awsui_header-variant-full-page_14iqq_irvl0_456",
  "header-cover": "awsui_header-cover_14iqq_irvl0_459",
  "content": "awsui_content_14iqq_irvl0_305",
  "content-fit-height": "awsui_content-fit-height_14iqq_irvl0_487",
  "content-inner": "awsui_content-inner_14iqq_irvl0_493",
  "with-header": "awsui_with-header_14iqq_irvl0_500",
  "footer": "awsui_footer_14iqq_irvl0_504",
  "with-divider": "awsui_with-divider_14iqq_irvl0_508"
};
  