
    import './styles.scoped.css';
    export default {
  "arrow": "awsui_arrow_xjuzf_hg5hv_289",
  "arrow-outer": "awsui_arrow-outer_xjuzf_hg5hv_293",
  "arrow-inner": "awsui_arrow-inner_xjuzf_hg5hv_293",
  "arrow-position-right-top": "awsui_arrow-position-right-top_xjuzf_hg5hv_335",
  "arrow-position-right-bottom": "awsui_arrow-position-right-bottom_xjuzf_hg5hv_335",
  "arrow-position-left-top": "awsui_arrow-position-left-top_xjuzf_hg5hv_338",
  "arrow-position-left-bottom": "awsui_arrow-position-left-bottom_xjuzf_hg5hv_338",
  "arrow-position-top-center": "awsui_arrow-position-top-center_xjuzf_hg5hv_341",
  "arrow-position-top-right": "awsui_arrow-position-top-right_xjuzf_hg5hv_341",
  "arrow-position-top-left": "awsui_arrow-position-top-left_xjuzf_hg5hv_341",
  "arrow-position-top-responsive": "awsui_arrow-position-top-responsive_xjuzf_hg5hv_341",
  "arrow-position-bottom-center": "awsui_arrow-position-bottom-center_xjuzf_hg5hv_344",
  "arrow-position-bottom-right": "awsui_arrow-position-bottom-right_xjuzf_hg5hv_344",
  "arrow-position-bottom-left": "awsui_arrow-position-bottom-left_xjuzf_hg5hv_344",
  "arrow-position-bottom-responsive": "awsui_arrow-position-bottom-responsive_xjuzf_hg5hv_344",
  "body": "awsui_body_xjuzf_hg5hv_492",
  "body-overflow-visible": "awsui_body-overflow-visible_xjuzf_hg5hv_528",
  "has-dismiss": "awsui_has-dismiss_xjuzf_hg5hv_532",
  "dismiss": "awsui_dismiss_xjuzf_hg5hv_537",
  "dismiss-control": "awsui_dismiss-control_xjuzf_hg5hv_545",
  "header-row": "awsui_header-row_xjuzf_hg5hv_549",
  "header": "awsui_header_xjuzf_hg5hv_549",
  "content": "awsui_content_xjuzf_hg5hv_574",
  "content-overflow-visible": "awsui_content-overflow-visible_xjuzf_hg5hv_582",
  "container": "awsui_container_xjuzf_hg5hv_730",
  "container-body": "awsui_container-body_xjuzf_hg5hv_738",
  "container-body-variant-annotation": "awsui_container-body-variant-annotation_xjuzf_hg5hv_778",
  "container-body-size-small": "awsui_container-body-size-small_xjuzf_hg5hv_783",
  "fixed-width": "awsui_fixed-width_xjuzf_hg5hv_786",
  "container-body-size-medium": "awsui_container-body-size-medium_xjuzf_hg5hv_790",
  "container-body-size-large": "awsui_container-body-size-large_xjuzf_hg5hv_797",
  "container-arrow": "awsui_container-arrow_xjuzf_hg5hv_809",
  "container-arrow-position-right-top": "awsui_container-arrow-position-right-top_xjuzf_hg5hv_813",
  "container-arrow-position-right-bottom": "awsui_container-arrow-position-right-bottom_xjuzf_hg5hv_813",
  "container-arrow-position-left-top": "awsui_container-arrow-position-left-top_xjuzf_hg5hv_830",
  "container-arrow-position-left-bottom": "awsui_container-arrow-position-left-bottom_xjuzf_hg5hv_830",
  "container-arrow-position-top-center": "awsui_container-arrow-position-top-center_xjuzf_hg5hv_847",
  "container-arrow-position-top-right": "awsui_container-arrow-position-top-right_xjuzf_hg5hv_847",
  "container-arrow-position-top-left": "awsui_container-arrow-position-top-left_xjuzf_hg5hv_847",
  "container-arrow-position-top-responsive": "awsui_container-arrow-position-top-responsive_xjuzf_hg5hv_847",
  "container-arrow-position-bottom-center": "awsui_container-arrow-position-bottom-center_xjuzf_hg5hv_863",
  "container-arrow-position-bottom-right": "awsui_container-arrow-position-bottom-right_xjuzf_hg5hv_867",
  "container-arrow-position-bottom-left": "awsui_container-arrow-position-bottom-left_xjuzf_hg5hv_871",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_xjuzf_hg5hv_1",
  "refresh": "awsui_refresh_xjuzf_hg5hv_1041",
  "root": "awsui_root_xjuzf_hg5hv_1063",
  "no-wrap": "awsui_no-wrap_xjuzf_hg5hv_1095",
  "root-filtering-token": "awsui_root-filtering-token_xjuzf_hg5hv_1099",
  "trigger": "awsui_trigger_xjuzf_hg5hv_1103",
  "overflow-ellipsis": "awsui_overflow-ellipsis_xjuzf_hg5hv_1110",
  "trigger-type-text": "awsui_trigger-type-text_xjuzf_hg5hv_1118",
  "trigger-type-filtering-token": "awsui_trigger-type-filtering-token_xjuzf_hg5hv_1154",
  "popover-inline-content": "awsui_popover-inline-content_xjuzf_hg5hv_1158"
};
  