import React from 'react'
import { RadAdaptiveList } from '../common/RadAdaptiveList'

export function PersonList () {
  return (
    <RadAdaptiveList
      entity='person'
      create={true}
      fields={[
        { header: 'Name', name: 'name', link: true },
        'email'
      ]}
    />
  )
}
