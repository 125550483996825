
    import './styles.scoped.css';
    export default {
  "tabs-header": "awsui_tabs-header_14rmt_1xftp_290",
  "tabs-header-list": "awsui_tabs-header-list_14rmt_1xftp_298",
  "pagination-button": "awsui_pagination-button_14rmt_1xftp_316",
  "pagination-button-left": "awsui_pagination-button-left_14rmt_1xftp_323",
  "pagination-button-left-scrollable": "awsui_pagination-button-left-scrollable_14rmt_1xftp_326",
  "pagination-button-right": "awsui_pagination-button-right_14rmt_1xftp_330",
  "pagination-button-right-scrollable": "awsui_pagination-button-right-scrollable_14rmt_1xftp_333",
  "tabs-tab": "awsui_tabs-tab_14rmt_1xftp_338",
  "tabs-tab-label": "awsui_tabs-tab-label_14rmt_1xftp_348",
  "tabs-tab-header-container": "awsui_tabs-tab-header-container_14rmt_1xftp_359",
  "tabs-tab-dismiss": "awsui_tabs-tab-dismiss_14rmt_1xftp_370",
  "tabs-tab-action": "awsui_tabs-tab-action_14rmt_1xftp_370",
  "refresh": "awsui_refresh_14rmt_1xftp_374",
  "tabs-tab-disabled": "awsui_tabs-tab-disabled_14rmt_1xftp_378",
  "tabs-tab-link": "awsui_tabs-tab-link_14rmt_1xftp_420",
  "tabs-tab-active": "awsui_tabs-tab-active_14rmt_1xftp_498",
  "tabs-header-with-divider": "awsui_tabs-header-with-divider_14rmt_1xftp_505",
  "tabs-tab-focusable": "awsui_tabs-tab-focusable_14rmt_1xftp_509",
  "root": "awsui_root_14rmt_1xftp_513",
  "tabs": "awsui_tabs_14rmt_1xftp_290",
  "tabs-content": "awsui_tabs-content_14rmt_1xftp_551",
  "fit-height": "awsui_fit-height_14rmt_1xftp_555",
  "tabs-content-active": "awsui_tabs-content-active_14rmt_1xftp_561",
  "tabs-content-wrapper": "awsui_tabs-content-wrapper_14rmt_1xftp_575",
  "with-paddings": "awsui_with-paddings_14rmt_1xftp_575",
  "tabs-container-content-wrapper": "awsui_tabs-container-content-wrapper_14rmt_1xftp_586",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_14rmt_1xftp_597",
  "tabs-tab-focused": "awsui_tabs-tab-focused_14rmt_1xftp_601"
};
  