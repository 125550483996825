import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import * as pluralize from 'pluralize'
import { toTitleCase } from 'titlecase'
import { AppContext } from './../App'
import { RadSideNavigation } from './RadSideNavigation'
import { RadBreadcrumbGroup } from './RadBreadcrumbGroup'
import AppLayout from '@cloudscape-design/components-themed/app-layout'
import Flashbar from '@cloudscape-design/components-themed/flashbar'

export function RadAppLayout ({ ...rest }) {
  const location = useLocation()
  const { error, setError } = useContext(AppContext)
  const { navigationOpen, setNavigationOpen } = useContext(AppContext)
  const notificationItems = error
    ? [{
      id: 'message_1',
      header: error.code,
      type: 'error',
      content: error.message,
      dismissible: true,
      dismissLabel: 'Dismiss message',
      onDismiss: () => setError(null)
    }]
    : []

  function breadcrumbs () {
    const items = []
    let acc = ''

    items.push({ text: 'Home', href: '/' })
    const paths = location.pathname.split('/')
    paths.splice(0, 1)

    const isEditing = paths[paths.length - 1] === 'edit'
    const isCreating = paths[paths.length - 1] === 'create'

    if (isCreating) {
      paths.pop()
      paths.forEach((x) => {
        acc += '/' + x
        items.push({ text: pluralize(toTitleCase(x)), href: acc })
      })
      items.push({ text: 'Create' })
    } else if (isEditing) {
      paths.pop()
      paths.forEach((x) => {
        acc += '/' + x
        items.push({ text: x !== paths[paths.length - 1] ? pluralize(toTitleCase(x)) : rest.name ?? x, href: acc })
      })
      items.push({ text: 'Edit' })
    } else {
      paths.forEach((x) => {
        acc += '/' + x
        items.push({ text: x !== paths[paths.length - 1] ? pluralize(toTitleCase(x)) : rest.name ?? pluralize(toTitleCase(x)), href: acc })
      })
    }

    return (
      <RadBreadcrumbGroup
        items={items}
        ariaLabel='Breadcrumbs'
      />
    )
  }

  if (!rest.breadcrumbs) {
    rest.breadcrumbs = breadcrumbs()
  }

  // https://cloudscape.design/components/app-layout
  return (
    <AppLayout
      ariaLabels={{
        navigation: 'Navigation',
        navigationClose: 'Navigation close',
        navigationToggle: 'Navigation toggle',
        notifications: 'Notifications',
        tools: 'Tools',
        toolsClose: 'Tools close',
        toolsToggle: 'Tools toggle'
      }}
      {...rest}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      navigation={<RadSideNavigation {...{ setNavigationOpen }} />}
      notifications={<Flashbar items={notificationItems} />}
      toolsHide
    />
  )
}
