import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import { useGet } from '../hooks/useGet'
import { RadBox } from './RadBox'
import { RadButton } from './RadButton'
import { RadCards } from './RadCards'
import { RadHeader } from './RadHeader'
import { RadLink } from './RadLink'
import { RadPagination } from './RadPagination'
import { RadSpaceBetween } from './RadSpaceBetween'
import { RadTable } from './RadTable'
import { RadTextFilter } from './RadTextFilter'
import { RadAppLayout } from './RadAppLayout'
import { RadExpandableSection } from './RadExpandableSection'
import { RadGrid } from './RadGrid'
import { RadMultiSelect } from '../common/RadSelect'
import { RadFormField } from '../common/RadFormField'
import { RadDatePicker } from '../common/RadDatePicker'
import { RadCheckbox } from '../common/RadCheckbox'
import { getBreadcrumbName, toTitleCase, isDateString } from './utilities'

export function RadAdaptiveList ({
  entity,
  fields,
  actions = null,
  emptyText = null,
  create = false,
  edit = false,
  rootHref = null,
  filters = [],
  expandableSections = [],
  onQueryChanged = null,
  hasSearchByOrg = false
}) {
  const pageLength = 20
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams() // for the ui
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchByOrg, setSearchByOrg] = useState(searchParams.get('searchByOrg') ?? false)
  const redirectURL = encodeURIComponent(`${rootHref ?? ''}/${entity}?${searchParams}`)
  const [queryParams, setQueryParams] = useState(new URLSearchParams({ // for the api
    search: filteringText,
    searchByOrg: searchByOrg,
    limit: pageLength,
    offset: (currentPageIndex - 1) * pageLength
  }))

  for (const key of filters.map(f => f.name)) {
    if (searchParams.has(key)) {
      queryParams.set(key, searchParams.get(key))
    }
  }

  const queryString = queryParams.toString()
  const { data: items, count } = useGet(`/api/${entity}?${queryString}`)

  useEffect(() => {
    if (onQueryChanged != null) {
      onQueryChanged(queryString)
    }
  }, [queryString, onQueryChanged])

  const header = (field) => {
    if (typeof field === 'string') {
      if (field.includes('.')) {
        return field.split('.')[0]
      } else {
        return field
      }
    }
    if (typeof field === 'object') {
      return field.header ?? field.name
    }
  }

  const content = (item, field) => {
    if (field === 'address') {
      return (
        <>
          <div>{item.address?.line1}</div>
          {item.address?.line2 && <div>{item.address?.line2}</div>}
          <div>{item.address?.city} {item.address?.state} {item.address?.zip}</div>
        </>
      )
    }

    let value
    if (typeof field === 'string') {
      value = field
      if (value.includes('.')) {
        const parts = value.split('.')
        value = parts.reduce((o, i) => (o ? o[i] : null), item)
      } else {
        value = item[value]
      }
    }
    if (typeof field === 'object') {
      if (typeof field.name === 'string') {
        value = field.name.includes('.') ? field.name.split('.').reduce((o, i) => (o ? o[i] : null), item) : item[field.name]
      }
      if (typeof field.content === 'function') {
        return field.content(item)
      }
    }
    if (field.link === true) {
      return <RadLink fontSize='inherit' href={`${rootHref ?? ''}/${entity}/${item.id}`}>{value}</RadLink>
    } else {
      return value
    }
  }

  const definitions = () => {
    const results = []
    for (const field of fields) {
      results.push({
        id: header(field),
        header: field.header ?? toTitleCase(header(field)),
        content: item => content(item, field) ?? '-'
      })
    }
    if (!isMobile && edit !== false) {
      results.push({
        id: 'actions',
        content: item => <RadButton variant='inline-icon' iconName='edit' href={`${rootHref ?? ''}/${entity}/${item.id}/edit?redirectURL=${redirectURL}`} />
      })
    }
    return results
  }

  const contentHeader = () => {
    return (
      <RadHeader
        variant='h1'
        counter={'(' + count + ')'}
        actions={
          <RadSpaceBetween direction='horizontal' size='xs'>
            {create !== false && <RadButton onClick={() => navigate(`${rootHref ?? ''}/${entity}/create?redirectURL=${redirectURL}`)}>Create</RadButton>}
            {actions != null && actions.map(x => <RadButton key={x.label} onClick={() => x.onClick({ search: filteringText })}>{x.label}</RadButton>)}
          </RadSpaceBetween>
        }
      >
        {getBreadcrumbName(entity)}
      </RadHeader>
    )
  }

  const renderFilter = (filter) => {
    switch (filter.type) {
      case 'MultiSelect':
        return (
          <RadMultiSelectAdaptiveListFilter
            key={filter.name}
            setQueryParams={setQueryParams}
            setCurrentPageIndex={setCurrentPageIndex}
            {...filter}
          />
        )
      case 'DateRange':
        return (
          <RadDateRangeAdaptiveListFilter
            key={filter.name}
            setQueryParams={setQueryParams}
            setCurrentPageIndex={setCurrentPageIndex}
            {...filter}
          />
        )
      default:
        return filter.component
    }
  }

  const filter = (
    <RadSpaceBetween size='m'>
      <RadTextFilter
        filteringPlaceholder='Search'
        filteringAriaLabel='Search'
        filteringText={filteringText}
        onChange={({ detail }) => setFilteringText(detail.filteringText)}
        onDelayedChange={({ detail }) => {
          setCurrentPageIndex(1)
          searchParams.delete('page')
          if (detail.filteringText) {
            searchParams.set('search', detail.filteringText)
            queryParams.set('search', detail.filteringText)
          } else {
            searchParams.delete('search')
            queryParams.delete('search')
          }
          setSearchParams(searchParams)
          setQueryParams(queryParams)
        }}
      />
      {hasSearchByOrg && <RadCheckbox
        onChange={(evt) => {
          searchParams.set('searchByOrg', evt.detail.checked)
          queryParams.set('searchByOrg', evt.detail.checked)
          setSearchParams(searchParams)
          setQueryParams(queryParams)
          setSearchByOrg(evt.detail.checked)
        }}
        checked={searchByOrg}
                         >
        Search By Organization
                         </RadCheckbox>}
      {
        filters.length > 0 &&
          <RadExpandableSection
            defaultExpanded={filters.filter(f => searchParams.has(f.name)).length > 0}
            headerText='Filters'
            headerDescription={`Additional ${entity} filters`}
          >
            <RadGrid gridDefinition={[{ colspan: { default: 12, m: 6 } }, { colspan: { default: 12, m: 6 } }]}>
              <RadSpaceBetween size='m'>{filters.filter((_, i) => i % 2 === 0).map(renderFilter)}</RadSpaceBetween>
              <RadSpaceBetween size='m'>{filters.filter((_, i) => i % 2 !== 0).map(renderFilter)}</RadSpaceBetween>
            </RadGrid>
          </RadExpandableSection>
      }
      {
        expandableSections.length > 0 &&
            expandableSections.map(({ key, component, ...rest }) => (
              <RadExpandableSection key={key} {...rest}>{component}</RadExpandableSection>
            ))
      }
    </RadSpaceBetween>
  )

  const pagination = (
    <RadPagination
      currentPageIndex={currentPageIndex}
      pagesCount={Math.ceil(count / pageLength)}
      onChange={({ detail }) => {
        searchParams.set('page', detail.currentPageIndex)
        queryParams.set('offset', (detail.currentPageIndex - 1) * pageLength)
        setSearchParams(searchParams)
        setQueryParams(queryParams)
        setCurrentPageIndex(detail.currentPageIndex)
      }}
      ariaLabels={{
        nextPageLabel: 'Next page',
        previousPageLabel: 'Previous page',
        pageLabel: pageNumber => `Page ${pageNumber} of all pages`
      }}
    />
  )

  const appContent = () => {
    if (isMobile) {
      return (
        <RadCards
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: 'Item selection'
          }}
          cardDefinition={{
            header: item => (
              <RadBox fontWeight='bold' fontSize='heading-l' padding={{ bottom: 's' }}>
                {definitions()[0].content(item)}
              </RadBox>
            ),
            sections: definitions().filter((x, i) => i > 0)
          }}
          cardsPerRow={[
            { cards: 1 },
            { minWidth: 640, cards: 2 },
            { minWidth: 960, cards: 3 },
            { minWidth: 1280, cards: 4 }
          ]}
          items={items}
          filter={filter}
          pagination={pagination}
          variant='full-page'
          empty={empty}
        />
      )
    } else {
      return (
        <RadTable
          variant='full-page'
          items={items}
          columnDefinitions={definitions().map(x => ({ ...x, cell: x.content }))}
          filter={filter}
          pagination={pagination}
          empty={empty}
        />
      )
    }
  }

  const empty = (
    <RadBox textAlign='center' padding={{ top: 'l' }}>
      <RadBox>
        {emptyText ?? 'No matches found.'}
      </RadBox>
    </RadBox>
  )

  if (items != null) {
    return (
      <RadAppLayout
        contentHeader={contentHeader()}
        content={appContent()}
      />
    )
  }
}

export function RadMultiSelectAdaptiveListFilter ({
  name,
  label,
  options,
  setQueryParams,
  setCurrentPageIndex,
  ...rest
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const selected = searchParams.get(name)?.split(',') ?? []
  const selectedOptions = options.filter(x => selected.includes(x.value))
  const updateParams = (params, selected) => {
    if (selected.length === 0) {
      params.delete(name)
    } else {
      params.set(name, selected.join(','))
    }
    return params
  }

  return (
    <RadFormField key={name} label={label}>
      <RadMultiSelect
        options={options}
        selectedOptions={selectedOptions}
        onChange={({ detail }) => {
          setSearchParams(params => updateParams(params, detail.selectedOptions.map(x => x.value)))
          setQueryParams(params => updateParams(params, detail.selectedOptions.map(x => x.value)))
          setCurrentPageIndex(1)
        }}
        {...rest}
      />
    </RadFormField>
  )
}

export function RadDateRangeAdaptiveListFilter ({
  name,
  labels: [fromLabel, toLabel],
  setQueryParams,
  setCurrentPageIndex
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [fromDate, toDate] = searchParams.get(name)?.split(',') ?? ['', '']
  const updateParams = (params, values) => {
    params.set(name, values.join(','))
    return params
  }

  // The user has entered two valid dates, ensure they are in the correct order
  useEffect(() => {
    if (isDateString(fromDate) && isDateString(toDate)) {
      const dates = [new Date(fromDate), new Date(toDate)]
      const [from, to] = [new Date(Math.min(...dates)), new Date(Math.max(...dates))]
      const [fromDateString, toDateString] = [from.toISOString().split('T')[0], to.toISOString().split('T')[0]]

      if (fromDateString !== fromDate && toDateString !== toDate) {
        setSearchParams(params => updateParams(params, [fromDateString, toDateString]))
        setQueryParams(params => updateParams(params, [fromDateString, toDateString]))
        setCurrentPageIndex(1)
      }
    }
  }, [fromDate, toDate])

  return (
    <RadSpaceBetween key={name} direction='horizontal' size='s'>
      <RadFormField key='fromDate' label={fromLabel}>
        <RadDatePicker
          value={fromDate}
          onChange={({ detail }) => {
            setSearchParams(params => updateParams(params, [detail.value, toDate]))
            setQueryParams(params => updateParams(params, [detail.value, toDate]))
            setCurrentPageIndex(1)
          }}
        />
      </RadFormField>
      <RadFormField key='toDate' label={toLabel} field='toDate'>
        <RadDatePicker
          value={toDate}
          onChange={({ detail }) => {
            setSearchParams(params => updateParams(params, [fromDate, detail.value]))
            setQueryParams(params => updateParams(params, [fromDate, detail.value]))
            setCurrentPageIndex(1)
          }}
        />
      </RadFormField>
    </RadSpaceBetween>
  )
}
