
    import './styles.scoped.css';
    export default {
  "key-value-pairs": "awsui_key-value-pairs_1y9fy_wasju_145",
  "group-title": "awsui_group-title_1y9fy_wasju_177",
  "group-list": "awsui_group-list_1y9fy_wasju_181",
  "group-list-item": "awsui_group-list-item_1y9fy_wasju_191",
  "term": "awsui_term_1y9fy_wasju_195",
  "key-label": "awsui_key-label_1y9fy_wasju_203",
  "detail": "awsui_detail_1y9fy_wasju_211",
  "info": "awsui_info_1y9fy_wasju_246"
};
  