import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAdaptiveList } from '../common/RadAdaptiveList'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadKeyValuePairs } from '../common/RadKeyValuePairs'

export function OrganizationList () {
  const [searchParams] = useSearchParams() // for the ui
  const { data: roleTypeOptions } = useGet('/api/option/projectOrganizationRoleType')
  const { data: orgFocusAreaOptions } = useGet('/api/option/organizationFocusArea')
  const { data: projFocusAreaOptions } = useGet('/api/option/projectFocusArea')
  const [statsPath, setStatsPath] = useState('/api/organization/statistics?')
  const { data: stats } = useGet(statsPath)
  const doneLoading = roleTypeOptions != null && orgFocusAreaOptions != null && projFocusAreaOptions != null && stats != null

  if (doneLoading) {
    return (
      <RadAdaptiveList
        entity='organization'
        create={true}
        fields={[
          { header: 'Name', name: 'name', link: true },
          { name: 'Type', field: 'organizationType.name' },
          'city',
          'state'
        ]}
        onQueryChanged={qs => {
          setStatsPath(`${statsPath.split('?')[0]}?${qs}`)
        }}
        filters={[{
          type: 'MultiSelect',
          name: 'roleType',
          label: "Organization's project role(s)",
          options: roleTypeOptions,
          placeholder: 'Select organization project role',
          expandToViewport: true
        }, {
          type: 'MultiSelect',
          name: 'orgFocusArea',
          label: 'Organization focus area',
          options: orgFocusAreaOptions,
          placeholder: 'Select organization focus area',
          expandToViewport: true
        }, {
          type: 'MultiSelect',
          name: 'projFocusArea',
          label: 'Project focus area',
          options: projFocusAreaOptions,
          placeholder: 'Select project focus area',
          expandToViewport: true
        }, {
          type: 'DateRange',
          name: 'dates',
          labels: ['Projects from', 'Projects to']
        }]}
        expandableSections={[{
          key: 'statistics',
          headerText: 'Statistics',
          headerDescription: 'Statistics for these organizations',
          defaultExpanded: ['search', 'projFocusArea', 'orgFocusArea', 'roleType', 'dates']
            .filter(k => searchParams.has(k))
            .length > 0,
          component: (
            <RadSpaceBetween size='m'>
              <RadKeyValuePairs
                columns={1}
                items={[{
                  label: 'Involved with more than one project',
                  value: `${stats.multiProjectInvolvement}%`
                }]}
              />
            </RadSpaceBetween>
          )
        }]}
      />
    )
  }
}
