
    import './styles.scoped.css';
    export default {
  "calendar": "awsui_calendar_1ykar_13z93_153",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1ykar_13z93_1",
  "root": "awsui_root_1ykar_13z93_180",
  "calendar-inner": "awsui_calendar-inner_1ykar_13z93_218",
  "calendar-header": "awsui_calendar-header_1ykar_13z93_222",
  "calendar-header-title": "awsui_calendar-header-title_1ykar_13z93_227",
  "calendar-next-btn": "awsui_calendar-next-btn_1ykar_13z93_235",
  "calendar-prev-btn": "awsui_calendar-prev-btn_1ykar_13z93_238",
  "calendar-grid": "awsui_calendar-grid_1ykar_13z93_241",
  "calendar-grid-dense": "awsui_calendar-grid-dense_1ykar_13z93_245",
  "calendar-grid-cell": "awsui_calendar-grid-cell_1ykar_13z93_253",
  "calendar-date-header": "awsui_calendar-date-header_1ykar_13z93_258",
  "calendar-date": "awsui_calendar-date_1ykar_13z93_258",
  "calendar-date-enabled": "awsui_calendar-date-enabled_1ykar_13z93_278",
  "calendar-date-current-page": "awsui_calendar-date-current-page_1ykar_13z93_288",
  "calendar-date-selected": "awsui_calendar-date-selected_1ykar_13z93_299",
  "calendar-date-current": "awsui_calendar-date-current_1ykar_13z93_288",
  "calendar-date-dense": "awsui_calendar-date-dense_1ykar_13z93_320",
  "date-inner": "awsui_date-inner_1ykar_13z93_328",
  "calendar-row": "awsui_calendar-row_1ykar_13z93_403",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_1ykar_13z93_407"
};
  